import Header from "./header";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssets, fetchURI } from "./redux/data/dataActions";
import axios from "axios";
import { TailSpin } from  'react-loader-spinner';
import deadGator from './assets/DeadGator.png';

import eye from './assets/eye.png';
import boots from './assets/boots.png';
import crocodile from './assets/crocodile.png';
import hatTape from './assets/hat_tape.png';
import hat from './assets/hat.png';
import scarf from './assets/scarf.png';
import sword from './assets/sword.png';
import tshirt from './assets/tshirt.png';
import capture from './assets/capture.png';
import background from './assets/background.png';

import eyeWhite from './assets/eye-white.png';
import bootsWhite from './assets/boots-white.png';
import crocodileWhite from './assets/crocodile-white.png';
import hatTapeWhite from './assets/hat_Tape-White.png';
import hatWhite from './assets/hat-white.png';
import scarfWhite from './assets/scarf-white.png';
import swordWhite from './assets/sword-white.png';
import tshirtWhite from './assets/tshirt-white.png';
import captureWhite from './assets/capture-white.png';
import backgroundWhite from './assets/background-white.png';

export default function Gators(storedState) {

  const dispatch = useDispatch();
  const state = useSelector((state) =>state);
  const [assets, setAssets] = useState();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [backgroundColor,setBackgroundColor] = useState("");
  const [blackIcons,setBlackIcons] = useState(false);

  const getAssets = async () => {
    dispatch(fetchURI());
    dispatch(fetchAssets(state.blockchain.account));
  }

  function getIcon(icon){
      if(blackIcons){
        switch (icon) {
          case "Boots":
            return boots;
            break;
          case "Background":
            return background;
            break;
          
          case "Body":
            return crocodile;
            break;
          
          case "Eyes":
            return eye;
            break;
          
          case "Head":
            return hat;
            break;
          
          case "Itens":
            return sword;
            break;
          
          case "Neck":
            return scarf;
            break;
          
          case "Apparel":
            return tshirt;
            break;
          
          case "Hat Tapes":
            return hatTape;
            break;
        
          case "Frame":
            return capture;
            break;
        
          default:
            break;
        }
      }else{

        switch (icon) {
          case "Boots":
            return bootsWhite;
            break;
          case "Background":
            return backgroundWhite;
            break;
          
          case "Body":
            return crocodileWhite;
            break;
          
          case "Eyes":
            return eyeWhite;
            break;
          
          case "Head":
            return hatWhite;
            break;
          
          case "Itens":
            return swordWhite;
            break;
          
          case "Neck":
            return scarfWhite;
            break;
          
          case "Apparel":
            return tshirtWhite;
            break;
          
          case "Hat Tapes":
            return hatTapeWhite;
            break;
            
          case "Frame":
            return captureWhite;
            break;
        
          default:
            break;
        }
      }
  }

  useEffect(()=>{
    const background = selected?.attributes?.filter(e=>e.trait_type == "Background");
    if(background == undefined) return;
    switch (background[0].value) {
      case "Underwater":
        setBlackIcons(false);
        setBackgroundColor({backgroundColor: "#0070f8"});
        break;
      case "Swamp":
        setBlackIcons(false);
        setBackgroundColor({backgroundColor: "#779883"});
        break;
      case "Town":
        setBlackIcons(false);
        setBackgroundColor({backgroundColor: "#000359"});
        break;
      case "desert":
        setBlackIcons(false);
        setBackgroundColor({backgroundColor: "#69b2ef"});
        break;
      case "Beach":
        setBlackIcons(true);
        setBackgroundColor({backgroundColor: "#5fe7ff"});
        break;
      case "Sunset":
        setBlackIcons(false);
        setBackgroundColor({backgroundColor: "#f89161"});
        break;
      case "Sky":
        setBlackIcons(false);
        setBackgroundColor({backgroundColor: "#071c39"});
        break;
      case "Yellow":
        setBlackIcons(true);
        setBackgroundColor({backgroundColor: "#f79824"});
        break;
      case "Red":
        setBlackIcons(true);
        setBackgroundColor({backgroundColor: "#e24666"});
        break;
      case "Orange":
        setBlackIcons(false);
        setBackgroundColor({backgroundColor: "#f29748"});
        break;
      case "Lavander":
        setBlackIcons(true);
        setBackgroundColor({backgroundColor: "#d5c6e0"});
        break;
      case "Purple":
        setBlackIcons(false);
        setBackgroundColor({backgroundColor: "#6c3082"});
        break;
      case "Gray":
        setBlackIcons(false);
        setBackgroundColor({backgroundColor: "#708090"})
        break;
      case "Lime":
        setBlackIcons(true);
        setBackgroundColor({backgroundColor: "#e2ef70"});
        break;
      case "Green":
        setBlackIcons(true);
        setBackgroundColor({backgroundColor: "#50c878"});
        break;
      case "Blue":
        setBlackIcons(false);
        setBackgroundColor({backgroundColor: "#1abdb8"});
        break;
      case "Pink":
        setBlackIcons(false);
        setBackgroundColor({backgroundColor: "#d77bba"});
        break;
      case "Aurora Borealis":
        setBlackIcons(false);
        setBackgroundColor({backgroundColor: "#071c39"})
        break;
      default:
        setBlackIcons(true);
        setBackgroundColor({backgroundColor: "#FFFFFF"})
        break;
    }


  },[selected])

  useEffect(() => {
    if(state.blockchain.account !== "" && state.blockchain.account != undefined ){
      getAssets();
    }
  }, [state.blockchain]);

  useEffect(async () => {
    setLoading(true);

    const cloned = state?.data?.assets?.map(e=>e);
    const list = await cloned?.sort((a,b)=> parseInt(a) - parseInt(b)).map(async (e) =>{
      return axios.get(await state.blockchain.smartContract.methods.tokenURI(e).call({
        from: state.blockchain.account}),{
          crossdomain:true
        });
    })
    
    if(list !== undefined ){
      Promise.all(list).then(e => {
          setAssets(e.flatMap((acc,idx) => [{...acc.data}]))
        setLoading(false)
      })
    }
  }, [state.data.assets]);


  return (
    <div>
    <Header/>
      <div className="flex flex-wrap gap-12 py-10 justify-center max-w-fit min-h-screen px-10">

        {!state.blockchain.account? (
          <div className="flex h-screen">
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded max-h-12" role="alert">
              <span className="block sm:inline">Please connect your wallet to continue</span>
            </div>
          </div>
          ): loading? (
            <div className="max-w-fit h-screen">
              <TailSpin color="#00BFFF" height={80} width={80} />
            </div>
          ): 
          assets?.map(e => {
          return(
            <div key={e.tokenId}>
              <div id="whoobe-3fery" className="w-full md:w-64 justify-center items-center bg-white rounded-lg flex flex-col" >
                  <img src={`${e.image.replace("ipfs://","https://cloudflare-ipfs.com/ipfs/")}`} 
                    onClick={r=>{setSelected(e); setShowModal(true);}}
                    onError={({ currentTarget }) => {currentTarget.onerror = null; currentTarget.src=deadGator;}} 
                    className="w-full h-auto object-cover rounded-lg shadow-lg  cursor-pointer transition duration-300 transform hover:-translate-y-1 hover:scale-110 "/>
                  <div id="whoobe-1okdg" className="w-full p-4 justify-start flex flex-col">
                      <h4 className="text-sm	text-center text-gray-600" id="whoobe-3mr7n">Pixel Gators</h4>
                      <h4 className="text-center" id="whoobe-3mr7n">Id. {e.tokenId}</h4>
                  </div>
              </div>
            </div>
          )}
        )}
        {
          showModal ? (
            <>
              <div className="justify-center pt-40 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none duration-500 relative transform transition-all translate-y-12 ease-out">
                <div className="relative w-auto mx-auto  max-w-4xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" style={{backgroundColor: backgroundColor?.backgroundColor}} >
                    {/*header*/}

                    {/*body*/}
                    <div className="relative pr-6 grid gap-8" style={{gridTemplateColumns: "2fr 2fr"}}>
                      <div>
                        <img src={`${selected.image.replace("ipfs://","https://cloudflare-ipfs.com/ipfs/")}`} className="w-full h-auto object-cover rounded-l-lg"/>
                      </div>
                      <div>
                        <div className="flex items-start justify-between p-2 rounded-t">
                        <button
                          className="ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          onClick={() => setShowModal(false)}
                        >
                          <span className="bg-transparent h-6 w-6 text-2xl block outline-none focus:outline-none" className={(blackIcons ? "text-black" : "text-white")}>
                            ×
                          </span>
                        </button>
                      </div>
                      <div className="grid grid-cols-2 gap-4 items-center h-5/6">
                        {selected?.attributes.map(e=>{
                          return(
                            <div key={e.trait_type} className="grid gap-2 border-0 rounded-lg p-1 figma-shadow items-center" style={{gridTemplateColumns: "1fr 2fr", backgroundColor: blackIcons ? "#0000002e" :"#ffffff2e" }}>
                              <img src={`${getIcon(e.trait_type)}`} className={(blackIcons ? "text-black" : "text-white") + " justify-self-center"} height={32} width={32}/>
                              <div className={(blackIcons ? "text-black" : "text-white") + " justify-self-centerstart"}>{e.value}</div>
                            </div>
                          )
                        })}
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="opacity-25 fixed inset-0 z-40 bg-black" onClick={() => setShowModal(false)}></div>
            </>
          ) : null
        
        }
         
   
        </div>
      </div>
  );
}
