import Header from "./header";
import React, { useEffect, useState, useRef, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from './config/config.json'; // with import
import store from './redux/store'
import { fetchAssets, fetchData, fetchURI } from "./redux/data/dataActions";


function closeMenu(e) {
  document.getElementById("menu-list").classList.toggle("hidden");
  document.getElementById("menu-hamburger").classList.toggle("hidden");
}


function showMenu(e) {
  
  
  document.getElementById("menu-list").classList.toggle("hidden");
  document.getElementById("menu-hamburger").classList.toggle("hidden");
  
}
export default function Gallery(storedState) {
  const dispatch = useDispatch();
  const state = useSelector((state) =>state);
  const [assets, setAssets] = useState([]);
  const [retornou, setRetornou] = useState(0);

  // const getConfig = async () => {
  //   SET_CONFIG(config);
  // };

  const getAssets = async () => {
    
    dispatch(fetchURI());
    dispatch(fetchAssets(state.blockchain.account));
  }

  

  useEffect(() => {
    if(state.blockchain.account !== "" && state.blockchain.account != undefined ){
      getAssets();
    }
  }, [state.blockchain.account]);

  useEffect(() => {
    setAssets(state.data.assets);
  }, [state.data.assets]);

  
  useEffect(() => {
    setAssets(state.data.assets);
  }, );




  return (
    <div>
    <Header/>
      <div className="flex flex-wrap gap-16 py-10 px-10 max-w-fit">
        {assets ? assets?.map(e => {
          return(
            <div key={ e}>

          <div className="max-w-2xl mx-auto">
              
              <div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm dark:bg-gray-800 dark:border-gray-700">
                  <a>
                      <img className="rounded-t-lg" src={`https://raw.githubusercontent.com/Weird-Whales/images/main/optimized-images/600x600/${e}.png`} alt="" onLoad={(e)=>{setRetornou(retornou+1)}}/>
                  </a>
                  <div className="p-5 flex text-center justify-center">
                      <a href="#" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                          Stake this
                          {/* <svg className="-mr-1 ml-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg> */}
                      </a>
                  </div>
              </div>
          </div>

            </div>
          )
        }) : <></>}
   
        </div>
        {/* Mobile */}
        <div className="relative bg-white  md:hidden md:w-90">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="justify-between items-center border-b-2 border-gray-100 py-6 md:space-x-10">
              <div className="lg:hidden">
                <div className="menu" id="menu-hamburger" onClick={(e) => showMenu(e)}>
                  <button className="navbar-burger flex items-center text-blue-600 p-3">
                    <svg
                      className="block h-4 w-4 fill-current"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/10240/svg"
                    >
                      <title>Menu</title>
                      <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                    </svg>
                  </button>
                </div>
              </div>
  
              <div className="list hidden transition ease-in-out delay-150" id="menu-list">
                <button className="navbar-close" onClick={(e) => closeMenu(e)}>
                  <svg
                    className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                    xmlns="http://www.w3.org/10240/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
                <div>
                <ul>
                  <li className="mb-1">
                    <a
                      className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                      href="#"
                    >
                      Alligators
                    </a>
                  </li>
                  <li className="mb-1">
                    <a
                      className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                      href="#"
                    >
                      Minting
                    </a>
                  </li>
                  <li className="mb-1">
                    <a
                      className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                      href="#"
                    >
                      My Alligators
                    </a>
                  </li>
                  <li className="mb-1">
                    <a
                      className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                      href="#"
                    >
                      Attributes
                    </a>
                  </li>
                </ul>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
