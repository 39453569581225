import ReactDOM from "react-dom"
import { Provider, useSelector } from 'react-redux'
import { Route, Routes } from "react-router"
import { BrowserRouter } from "react-router-dom"
import 'tailwindcss/tailwind.css'
import Gallery from "./gallery"
import Gators from "./gators"
import Home from "./home"
import store from './redux/store'
import Roadmap from "./roadmap"
import Stake from "./stake"
import './styles/global.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Footer from "./footer"




ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="gators" element={<Gators />} /> 
        <Route path="roadmap" element={<Roadmap />} /> 
        <Route path="gallery" element={<Gallery />} /> 
        <Route path="stake" element={<Stake />} />
      </Routes>
    </BrowserRouter>
    <Footer/>
  </Provider>,
  document.getElementById("root")
);