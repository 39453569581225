import "./styles/accordion.css";
import React, { useState, useRef } from "react";
import Chevron from "./chevron";

function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
  }

  return (
    <div className={`accordion__section`}>
      <button className={`accordion ${setActive} ${props.classes.includes("rounded-b-lg") && setActive==="active" ?"" :  props.classes}`} onClick={toggleAccordion}>
        <p className="accordion__title">{props.title}</p>
        <Chevron className="accordion__icon" width={10} fill={"#777"} />
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className={`accordion__content ${setActive==="active" && props.classes.includes("rounded-b-lg") ? props.classes : ""}`}
      >
        <div
          className={`accordion__text`}
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </div>
  );
}

export default Accordion;