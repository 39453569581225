// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};
const fetchCostSuccess = (payload) => {
  return {
    type: "CHECK_COST_SUCCESS",
    payload: payload,
  };
};

const fetchWhitelist = (payload) => {
  return {
    type: "CHECK_WHITELIST_SUCCESS",
    payload: payload,
  };
};

const fetchAssetSuccess = (payload) => {
  return {
    type: "CHECK_ASSETS",
    payload: payload,
  };
};

const fetchURISucess = (payload) => {
  return {
    type: "CHECK_URI",
    payload: payload,
  };
};

const fetchStakedSucess = (payload) => {
  return {
    type: "CHECK_ASSETSSTAKED",
    payload: payload,
  };
};


const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      console.log(store
        .getState()
        .blockchain.smartContract);
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      dispatch(
        fetchDataSuccess({
          totalSupply,
        })
      );

    let whitelist = await store
      .getState()
      .blockchain.smartContract.methods.whitelistOnly().call();
      
      dispatch(
        fetchWhitelist({
          whitelist,
        })
      );
      if(whitelist){
        let cost = await store
        .getState()
        .blockchain.smartContract.methods.whitelistCost()
        .call();
        dispatch(
          fetchCostSuccess({
            cost,
          })
        );
      }else{
        let cost = await store
          .getState()
          .blockchain.smartContract.methods.cost()
          .call();
          
        dispatch(
          fetchCostSuccess({
            cost,
          })
        );
      }
      

    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

export const fetchAssets = (address) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let assets = await store
        .getState()
        .blockchain.smartContract.methods.walletOfOwner(address)
        .call();
      dispatch(
        fetchAssetSuccess({
          assets
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};



export const fetchStaked = (address) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      console.log(store
        .getState());
      let assets = await store
        .getState()
        .blockchain.stakeContract.methods.despositedTokens(address)
        .call();
        console.log(assets);
      dispatch(
        fetchStakedSucess({
          assets
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};


export const fetchURI = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let assets = await store
        .getState()
        .blockchain.smartContract.methods.baseURI()
        .call();
      dispatch(
        fetchURISucess({
          assets
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
