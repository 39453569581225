import React, { useEffect, useState, useRef, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from './config/config.json'; // with import
import Header from "./header";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import gif from './assets/jacares.gif'; // with import

import one from './assets/1.png'; // with import
import two from './assets/2.png'; // with import
import three from './assets/3.png'; // with import
import four from './assets/4.png'; // with import
import five from './assets/5.png'; // with import
import six from './assets/6.png'; // with import
import seven from './assets/7.png'; // with import
import eight from './assets/8.png'; // with import
import nine from './assets/9.png'; // with import
import ten from './assets/10.png'; // with import
import eleven from './assets/11.png'; // with import
import twelve from './assets/12.png'; // with import
import thirteen from './assets/13.png'; // with import
import fourteen from './assets/14.png'; // with import
import fifteen from './assets/15.png'; // with import
import { fetchData } from "./redux/data/dataActions";
import Accordion from "./accordion";
import TimerComp from "./TimerComp";
import useTimer from "./hooks/useTimer";

export default function Home(storedState) {


  const {
    day,
    hour,
    minute,
    second,
  } = useTimer();

  var settings1 = {
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 5000,
    cssEase: "linear",
    waitForAnimate: false,
    arrows: false
  };
  var settings2 = {
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 5000,
    cssEase: "linear",
    waitForAnimate: false,
    rtl: true,
    arrows: false
  };
  
  var settings3= {
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 5000,
    cssEase: "linear",
    waitForAnimate: false,
    initialSlide: 10,
    arrows: false
  };

  const dispatch = useDispatch();
    const blockchain = useSelector((state) =>state.blockchain);
    const data = useSelector((state) => state.data);
    const [mintAmount, setMintAmount] = useState(1);
    const [claimingNft, setClaimingNft] = useState(false);
    const [CONFIG, SET_CONFIG] = useState({});
    const [whitelist, setWhitelist] = useState(false);
    const [permited, setPermited] = useState(false);
    

    const claimNFTs = () => { 
      let gasLimit = config.GAS_LIMIT;
      let totalCostWei = String(data.cost * mintAmount);
      let totalGasLimit = String(gasLimit * mintAmount);
      setClaimingNft(true);
      console.log(totalCostWei);
      blockchain.smartContract.methods
        .mint(blockchain.account,mintAmount)
        .send({
          gasLimit: String(totalGasLimit),
          to: config.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          setClaimingNft(false);
        })
        .then((receipt) => {
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
    };
    
  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };



  useEffect(() => {
    if(data.whitelist){
      setWhitelist(true);
      if(blockchain.account && blockchain.smartContract){
        blockchain.smartContract.methods.whitelisted(blockchain.account).call().then(resp =>{
          setPermited(resp);
        })
      }
    }else{
      setWhitelist(false);
      setPermited(true);

    }
   }, [data]);

  const getConfig = async () => {
    SET_CONFIG(config);
  };
  useEffect(() => {
    getConfig();
   }, []);
    

    function decrement(e) {
      if(mintAmount>1){
        setMintAmount(mintAmount-1);
      }
    }

    function increment(e) {
      setMintAmount(mintAmount+1);
    }

    
  useEffect(() => {
    getData();
  }, [blockchain.account]);
  

  return (
      <div>
      <Header/>
        {blockchain.errorMsg !== ""  ? (
          <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200 mx-80 mt-4" role="alert">
            <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" ></path></svg>
            <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
              {blockchain.errorMsg}
            </div>
          </div>
        ):null}

        <div className="bg-white ">
          <div className="max-w-7x px-10 grid gap-2 grid-cols-1 mt-10 ">
          <div className="grid grid-rows-1 grid-columns-1 grid-flow-col gap-4 justify-center">
              <img src={gif} width={600} height={600} className="rounded-lg"/>
            </div>
            <div className="w-full">

              {day > 0 || hour > 0 || minute > 0 || second > 0? (
                <div className="py-4 grid grid-cols-1 justify-items-center">
                  <div className="text-2xl font-bold py-4">Sale Starts in</div> 
                  
                  <div className="py-4 grid grid-cols-1 justify-items-center">
                    <TimerComp />
                  </div>
                </div>
            ): (whitelist ? config.WHITELIST_SUPPLY - data.totalSupply : config.MAX_SUPPLY - data.totalSupply) > 0 ? (

              <div className="flex justify-center pt-10 text-2xl">
                <div className="grid grid-cols-1 justify-items-center">
                <div className="grid grid-cols-2 justify-items-center items-end my-10">
                <div className="h-10 w-32 mr-10">
                  <div className="flex flex-row h-10 w-full rounded-lg bg-transparent figma-shadow">
                    <button data-action="decrement" className=" bg-white text-gray-600 hover:text-gray-700 hover:bg-gray-200 h-full w-20 rounded-l cursor-pointer outline-none" onClick={(e) => decrement(e)}>
                      <span className="text-2xl font-thin">−</span>
                    </button>
                    <input type="number" className="outline-none focus:outline-none text-center w-full bg-white font-semibold text-md hover:text-black focus:text-black md:text-basecursor-default flex items-center text-gray-700 outline-none" readOnly={true} name="custom-input-number" value={mintAmount} onChange={(e) => setMintAmount(e)} min="1"></input>
                    <button data-action="increment" className="bg-white text-gray-600 hover:text-gray-700 hover:bg-gray-200 h-full w-20 rounded-r cursor-pointer" onClick={(e) => increment(e)}>
                      <span className="text-2xl font-thin">+</span>
                    </button>
                  </div>
                </div>
                <button className="bg-gradient-to-r figma-shadow from-blue-600 to-blue-900 text-white font-medium py-2 px-4 rounded-lg inline-flex items-center disabled:opacity-50 text-sm lg:text-base max-w-xs lg:max-w-lg" disabled={ !permited || (blockchain.account == "" ||blockchain.account == null) || claimingNft} onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}>
                  <span> { blockchain.account != "" && blockchain.account != undefined ? "Mint " + mintAmount + (mintAmount > 1 ? " gators": " gator") : "Connect your wallet"}</span>
                </button>
              </div>
              {blockchain.smartContract ? (
                <div className="grid grid-cols-1 justify-items-center">
                  <div className="px-4 text-center font-1xl">Minted: {data.totalSupply}/{whitelist ? config.WHITELIST_SUPPLY : config.MAX_SUPPLY} </div>
                  <div className="px-4 text-center font-1xl">Current price: {data.cost/1000000000000000000 + " " + config.NETWORK.SYMBOL}</div>
                </div>
              ) :(
                <div className="grid grid-cols-1 justify-items-center">
                  <div className="px-4 text-center font-1xl">Connect your wallet to check the price and amount remaining</div>
                </div>

              )}
              
              </div>
              </div>
              ) : (
              <div className="grid grid-cols-1 justify-items-center">
                <h2 className="text-lg text-black font-semibold grid pt-14 pl-20">
                  The minting process has been done but you can find us on<br/>
                  <a href="https://paintswap.finance/marketplace/collections/0x46350eda48b3aafc4c403ff02c024e76ae22e803"> Paint Swap</a>
                  </h2>
              </div>
              )}
            
            </div>
            
            </div>
            <div className="background-gradient">


              <p className="font-semibold figma-text-shadow text-5xl trade-mark my-4 text-yellow-500 text-center mb-10 pt-20">Pixel Gators</p>
              <p className="font-semibold figma-text-shadow text-2xl trade-mark my-4 text-white text-center mb-10 pt-10">DANGER!<br/>
                Gators inhabit this area. <br/>
                Attacks cause injury or death
              </p>
            <Slider {...settings1}>
              <div className="px-1"><img src={one} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={two} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={three} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={four} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={five} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={six} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={seven} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={eight} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={nine} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={ten} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={eleven} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={twelve} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={thirteen} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={fourteen} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={fifteen} width={256} height={256} className="rounded-lg "/></div>
            </Slider>
            <Slider {...settings2}>
              <div className="px-1"><img src={one} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={two} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={three} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={four} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={five} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={six} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={seven} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={eight} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={nine} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={ten} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={eleven} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={twelve} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={thirteen} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={fourteen} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={fifteen} width={256} height={256} className="rounded-lg "/></div>
            </Slider>
            <Slider {...settings3 }>
              <div className="px-1"><img src={one} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={two} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={three} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={four} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={five} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={six} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={seven} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={eight} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={nine} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={ten} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={eleven} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={twelve} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={thirteen} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={fourteen} width={256} height={256} className="rounded-lg "/></div>
              <div className="px-1"><img src={fifteen} width={256} height={256} className="rounded-lg "/></div>
            </Slider>

            
            <p className="font-semibold figma-text-shadow text-5xl my-4 text-white text-center mb-10 py-20">Frequently Asked Questions</p>
            <div className="px-4 lg:px-20 py-10 figma-shadow">
              <Accordion
                classes="rounded-t-lg figma-shadow"
                title="What is Pixel Gators?"
                content="Pixel gators is a collection of 4096 NFT Tokens random uniques pixel art alligators.<br/>
                The collections is randomly generated, based in 100+ traits, making possible more
                than a billion combinations."
              />
              <Accordion
                classes="figma-shadow"
                title="Will there be a Presale?"
                content="Yes, the presale will occours 24 hours before the public sale and whitelist spot will be given in giveaways on twitter and discord.<br/>
                Then follow us on <a target='_blank' href='https://twitter.com/ftmPixelGators'>@ftmPixelGators </a> and enter on our <a target='_blank' href='https://discord.gg/KJNeWVetat'>Discord </a>"
              />
              <Accordion
                classes="figma-shadow"
                title="When Sale Starts?"
                content="On Feb 12 at 15:00 PM GMT, will start the presale, and presale will be active for 24 hours, on Feb 13 at 15:00 PM GMT starts the public sale."
              />
              <Accordion
                classes="figma-shadow"
                title="How many Tokens will be on Presale?"
                content="Only 25% of avaiable tokens, 1000 tokens in total, will be on presale.
                And the price will have a discount of 20% off from public price"
              />
              <Accordion
                classes="figma-shadow"
                title="Why should I Buy?"
                content="The Pixel Gators includes monthly rewards (badges) for holders, and futurely stakers, and 10% of mint ammount will be donated for charity. <br/>
                Holder will get free access to whitelist in future projects. <br/>
                If you are lucky and get one of 3 rarests Gators, you win a prize (500FTM, 300FTM and 200FTM).<br/>
                And every month holded a Pixel Gator, you will be able to get a badge. Another NFT Token!"
                    />
              <Accordion
                classes="figma-shadow"
                title="Do you have any Anti-Snipe process to protect the Prize?"
                content="Sure, we have are running process to only reveal the info of NFT after they are minted"
                    />
              <Accordion
                classes="figma-shadow"
                title="What is Pixel Gators Badges?"
                content="Is another NFT for the members, every month (30 days exactly) holded, you will be able to claim a Pixel Gator Badge, for every NFT you holded a month.
                You can read more about in our <a target='_blank' href='https://discord.gg/KJNeWVetat'>Medium</a>."
                    />
              <Accordion
                classes="rounded-b-lg figma-shadow"
                title="What is the Whitelist Price and Public Sale Price?"
                content="The prices will be start on 10FTM public sale and 8FTM for whitelist.<br/>
                If Fantom price grow up too much, the prices will be revised."
                    />
            </div>
          </div>

        </div>
      </div>
  );
}
